import React, { useEffect, useState } from "react";
import { useClassContext } from "../../context/ClassContext";
import RegularModal from "../../components/Modals/RegularModal/RegularModal";
import AddNewUser from "./components/AddNewUser/AddNewUser";
import ChangeTeacher from "./components/ChangeTeacher/ChangeTeacher";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import Header from "./components/Header/Header";
import OpenClass from "./components/OpenClass/OpenClass";
import ClassNav from "./components/ClassNav/ClassNav";
import StudentCard from "./components/StudentCard/StudentCard";

const Clase = () => {
  const {
    loading,
    students,
    addUserModal,
    setAddUserModal,
    clase,
    showChangeTeacherModal,
    setShowChangeTeacherModal,
  } = useClassContext();

  const hideModal = () => {
    if (addUserModal) setAddUserModal(false);
    if (showChangeTeacherModal) setShowChangeTeacherModal(false);
  };

  function getTitle() {
    if (addUserModal) {
      return "Agregar usuarios a la clase";
    } else if (showChangeTeacherModal) {
      return "Cambiar clase de maestra";
    } else {
      return "";
    }
  }

  console.log(students.length)
  return (
    <div className="class-page-main-container d-flex flex-column">
      <RegularModal
        show={addUserModal || showChangeTeacherModal}
        onHide={hideModal}
        title={getTitle()}
      >
        {addUserModal && <AddNewUser onHide={() => setAddUserModal(false)} />}
        {showChangeTeacherModal && <ChangeTeacher />}
      </RegularModal>
      {loading ? (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <CustomLoader />
        </div>
      ) : (
        <>
          <Header />
          <OpenClass />
          <ClassNav />
          <div className="students-cards-container d-flex flex-column border-top">
            {students.map((student, i) => {
              return <StudentCard student={student} key={`${student.id}-student-${i}`} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Clase;
